import {
  Avatar,
  Button,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Modal,
  Row,
} from "antd";
import React, { useState } from "react";
import home from "./house.jpg";
import styles from "../style/landingPage.module.css";
import {
  CloseCircleOutlined,
  PlusCircleTwoTone,
  MinusCircleTwoTone,
  PlusCircleOutlined,
  MinusCircleOutlined,
  UserAddOutlined,
  ClockCircleOutlined
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { getCategoryDataHandle } from "../../PropertyComp/utils/helper";
import moment from "moment";

const ScheduleModal = ({
  showModal,
  handleOk,
  handleCancel,
  defaultValues,
  setDefaultValues,
  currentClickedItem,
  form,
  decrementDuration,
  selectedDuration,
  incrementDuration,
  selectedClient,
  setShowClientList,
  showClientList,
  showDeallocateConfirm,
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const history = useHistory();
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleRedirect = (item) => {
    const {category,categoryId, status}= getCategoryDataHandle({notificatonData:item})
    history.push({ pathname: "/transactions", state: { category, categoryId, status} });
  }
  const handleValuesChange = (changedValues) => {
    if (changedValues.description !== undefined) {
     setDefaultValues((prevValues) => ({
        ...prevValues,
        description: changedValues.description,
      }));
    }
  
    if (changedValues.title !== undefined) {
    setDefaultValues((prevValues) => ({
        ...prevValues,
        title: changedValues.title,
      }));
    }

    if (changedValues.scheduleTimeArray !== undefined) {
    setDefaultValues((prevValues) => ({
        ...prevValues,
        scheduleTimeArray: changedValues.scheduleTimeArray,
      }));
    }
  };
  
  
  return (
    <Modal
      visible={showModal}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={false}
      initialValues={defaultValues}
    >
      <div className={styles.createtitle} style={{ color: "#8C8C8C" }}>
        Schedule
      </div>
      <Form form={form} onFinish={handleOk} layout="vertical" onValuesChange={handleValuesChange}>
        {currentClickedItem.type === "CONTRACT_SCHEDULE" ? (
          <>
            <div
              style={{
                display: "flex",
                marginBottom: "15px",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "20%" }}>
                {currentClickedItem?.taggedPersonIds?.[0]?.profileImg
                  ?.original ? (
                  <Avatar
                    size={67}
                    src={
                      currentClickedItem?.taggedPersonIds?.[0]?.profileImg
                        ?.original
                    }
                  />
                ) : currentClickedItem?.taggedPersonIds?.[0]?.firstName ? (
                  <Avatar size={67} style={{ background: "#085191" }}>
                    {(currentClickedItem?.taggedPersonIds?.[0]?.firstName
                      ? currentClickedItem?.taggedPersonIds?.[0]?.firstName?.[0]?.toUpperCase()
                      : "") +
                      "" +
                      (currentClickedItem?.taggedPersonIds?.[0]?.lastName
                        ? currentClickedItem?.taggedPersonIds?.[0]?.lastName?.[0]?.toUpperCase()
                        : "")}
                  </Avatar>
                ) : (
                  ""
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  textAlign: "center",
                  cursor:"pointer"
                }}
                onClick={() => handleRedirect(currentClickedItem)}
              >
                <div style={{ marginLeft: "-30px" }}>
                <Image
                    src={currentClickedItem?.propertyImages?.[0]?.imageUrl || home}
                    preview={false}
                    height={"70px"}
                    width={"150px"}
                    alt=""
                    style={{ objectFit: "contain" }}
                  />
                </div>

                <div>
                  <span style={{ color: "#4CAAFF", fontSize: "16px" }}>
                    {currentClickedItem?.fullAddress}
                  </span>
                </div>
              </div>
              <div
                style={{
                  width: "25%",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                {currentClickedItem?.startTimestamp && moment(currentClickedItem.startTimestamp).isValid() && moment(currentClickedItem.startTimestamp).format("MM/DD/YYYY")}
              </div>
            </div>

            <Form.Item
              className="lbl"
              name="title"
              label="Title"
              rules={[{ required: true, message: "Please Enter Title!" }]}
            >
              <Input placeholder="" allowClear disabled={true} />
            </Form.Item>

            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  className="lbl"
                  name="description"
                  label="Description"
                  rules={[{ required: true }]}
                >
                  <Input.TextArea placeholder="Add description" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  className="lbl"
                  name="scheduleTimeArray"
                  label={<span>Date/Time<ClockCircleOutlined style={{marginLeft:"5px",color:"cornflowerblue"}}/></span>}
                >
                 <DatePicker
                      style={{ width: "100%" }}
                      use12Hours
                      showTime= {{format: 'h:mm', minuteStep: 15 } }
                     format={'MM-DD-YYYY h:mm A'}
                    onChange={handleDateChange}
                    />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={9} sm={24} md={9} lg={9} xl={9}>
                {defaultValues.timeInterval === 1 ? (
                  <MinusCircleOutlined
                    style={{ float: "right", fontSize: 26, marginTop: 40 }}
                  />
                ) : (
                  <MinusCircleTwoTone
                    style={{ float: "right", fontSize: 26, marginTop: 40 }}
                    onClick={() => decrementDuration()}
                  />
                )}
              </Col>
              <Col
                xs={6}
                sm={24}
                md={6}
                lg={6}
                xl={6}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Form.Item
                  className="lbl"
                  name="timeInterval"
                  label="Duration"
                  disabled
                >
                  <h4
                    style={{
                      fontSize: "22px",
                      color: "black",
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "lightgray",
                      borderRadius: "6px",
                    }}
                  >
                    {selectedDuration()}
                  </h4>
                </Form.Item>
              </Col>
              <Col xs={9} sm={24} md={9} lg={9} xl={9}>
                {defaultValues.timeInterval === 16 ? (
                  <PlusCircleOutlined
                    style={{ float: "left", fontSize: 26, marginTop: 40 }}
                  />
                ) : (
                  <PlusCircleTwoTone
                    onClick={() => incrementDuration()}
                    style={{ float: "left", fontSize: 26, marginTop: 40 }}
                  />
                )}
              </Col>
            </Row>

            <Row gutter={16} justify="end">
              <Col>
                <Button default onClick={() => handleCancel()}>
                  Cancel
                </Button>
              </Col>
              <Col>
                <Form.Item>
                   <Button type="primary" htmlType="submit">
                      Save
                    </Button>
                </Form.Item>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row
              gutter={(24, 24)}
              style={{
                display: "flex",
                justifyContent: "center",
                rowGap: "10px",
              }}
            >
              <>
                {selectedClient?.profileImg?.original ? (
                  <>
                    <Avatar
                      onClick={() => {
                        if (
                          currentClickedItem.type === "BIRTHDAY" ||
                          currentClickedItem.type === "CLIENT_EVENT"
                        ) {
                          return;
                        }
                        setShowClientList(!showClientList);
                      }}
                      size={67}
                      className={styles.useravtar}
                      src={selectedClient.profileImg.original}
                    />
                    {currentClickedItem.type === "BIRTHDAY" ||
                    currentClickedItem.type === "CLIENT_EVENT" ? null : (
                      <div
                        style={{
                          marginLeft: -15,
                        }}
                        onClick={() => showDeallocateConfirm()}
                      >
                        <Avatar
                          size="small"
                          icon={
                            <CloseCircleOutlined style={{ color: "#1890ff" }} />
                          }
                        />
                      </div>
                    )}
                  </>
                ) : selectedClient?.firstName ? (
                  <>
                    <Avatar
                      size={67}
                      style={{ background: "#085191" }}
                      onClick={() => {
                        if (
                          currentClickedItem.type === "BIRTHDAY" ||
                          currentClickedItem.type === "CLIENT_EVENT"
                        ) {
                          return;
                        }
                        setShowClientList(!showClientList);
                      }}
                    >
                      {(selectedClient?.firstName
                        ? selectedClient.firstName?.[0]?.toUpperCase()
                        : "") +
                        "" +
                        (selectedClient?.lastName
                          ? selectedClient.lastName?.[0]?.toUpperCase()
                          : "")}
                    </Avatar>
                    {currentClickedItem.type === "BIRTHDAY" ||
                    currentClickedItem.type === "CLIENT_EVENT" ? null : (
                      <div
                        style={{
                          marginLeft: -15,
                        }}
                        onClick={() => showDeallocateConfirm()}
                      >
                        <Avatar
                          size="small"
                          icon={
                            <CloseCircleOutlined style={{ color: "#1890ff" }} />
                          }
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <Avatar
                    shape="circle"
                    onClick={() => {
                      if (
                        currentClickedItem.type === "BIRTHDAY" ||
                        currentClickedItem.type === "CLIENT_EVENT"
                      ) {
                        return;
                      }
                      setShowClientList(!showClientList);
                    }}
                    size={67}
                    style={{
                      background: "#085191",
                      // marginLeft: "40%",
                      cursor: "pointer",
                      fontSize: "24px",
                      display: "flex",
                      alignItems: "center",
                      height: "65px",
                    }}
                  >
                    <UserAddOutlined
                      style={{
                        fontSize: "24px",
                        fill: "#085191",
                        color: "white",
                      }}
                    />
                  </Avatar>
                )}
              </>
            </Row>

            <Row gutter={16}>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                style={{ marginTop: "2rem" }}
              >
                <Form.Item
                  className="lbl"
                  name="title"
                  label="Title"
                  rules={[{ required: true }]}
                >
                  <Input
                    placeholder="Enter Title"
                    disabled={selectedClient?.type === "BIRTHDAY"}
                    allowClear
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  className="lbl"
                  name="description"
                  label="Description"
                  rules={[{ required: true }]}
                >
                  <Input.TextArea
                    placeholder="Add description"
                    disabled={selectedClient?.type === "BIRTHDAY"}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  className="lbl"
                  name="scheduleTimeArray"
                  label={<span>Date/Time
                  <ClockCircleOutlined style={{marginLeft:"5px",color:"cornflowerblue"}}/></span>}
                >
                 <DatePicker
                      style={{ width: "100%" }}
                      use12Hours
                      showTime= {{format: 'h:mm', minuteStep: 15 } }
                     format={'MM-DD-YYYY h:mm A' }
                    onChange={handleDateChange}
                  
                    />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={9} sm={24} md={9} lg={9} xl={9}>
                {defaultValues.timeInterval === 1 ? (
                  <MinusCircleOutlined
                    style={{ float: "right", fontSize: 26, marginTop: 40 }}
                  />
                ) : (
                  <MinusCircleTwoTone
                    style={{ float: "right", fontSize: 26, marginTop: 40 }}
                    onClick={() => decrementDuration()}
                  />
                )}
              </Col>
              <Col
                xs={6}
                sm={24}
                md={6}
                lg={6}
                xl={6}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Form.Item className="lbl" name="timeInterval" label="Duration">
                  <h4
                    style={{
                      fontSize: "22px",
                      color: "black",
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "lightgray",
                      borderRadius: "6px",
                    }}
                  >
                    {selectedDuration()}
                  </h4>
                </Form.Item>
              </Col>
              <Col xs={9} sm={24} md={9} lg={9} xl={9}>
                {defaultValues.timeInterval === 16 ? (
                  <PlusCircleOutlined
                    style={{ float: "left", fontSize: 26, marginTop: 40 }}
                  />
                ) : (
                  <PlusCircleTwoTone
                    onClick={() => incrementDuration()}
                    style={{ float: "left", fontSize: 26, marginTop: 40 }}
                  />
                )}
              </Col>
            </Row>

            <Row gutter={16} justify="end">
              <Col>
                <Button default onClick={() => handleCancel()}>
                  Cancel
                </Button>
              </Col>
              <Col>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Save
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default ScheduleModal;
