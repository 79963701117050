import { Button, Modal, Select, Form, message } from 'antd';
import React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { clientAction } from '../state/actions';
import styles from "../styles/filterContacts.module.css"

const FilterClients = ({ 
  setPage, 
  setPersonId, 
  isSearchModalOpen,
  handleSearchCancel,
  onFilterChange, 
  isClearAllDisabled, 
  setIsClearAllDisabled, 
  setIsSearchModalOpen,
  listSource,
  filterNotApplied,
  setfilterNotApplied,
  form,
  isfilterArchive,
  page
}) => {
  const roleHashTags = ["TITLE REP", "BUYER", "SELLER", "STAGER", "MORTGAGE", "CUSTOMER", "PHOTOGRAPHER", "PROPERTY INSPECTOR"];
  const dispatch = useDispatch();

  const handleApplyButtonClick = () => {
    setfilterNotApplied(false);
    dispatch(clientAction.clearGetClientData());
    handleSearchCancel();
    onFilterChange(true);
    if(!listSource){
      setPersonId("");
    }
    setPage([1]);
  };

  const handleClearAllButtonClick = () => {
    if(!filterNotApplied){
      dispatch(clientAction.clearGetClientData());
      if(!listSource){
        setPersonId("");
      }
      setPage([1]);
    }
    form?.resetFields();
    handleSearchCancel();
    setIsClearAllDisabled({...isClearAllDisabled, applyButton: true, clearBtn: true});
    onFilterChange(false);
    setfilterNotApplied(true);
  };

  const handleModalCancel = () => {
    if(filterNotApplied){
        setIsClearAllDisabled({...isClearAllDisabled, applyButton: true, clearBtn: true});
        form?.resetFields();
      }
    setIsSearchModalOpen(false);
  };

  useEffect(() => {
    const values = form?.getFieldsValue() || {};
    const { selectedRoles, selectedSortBy, selectedSortOrder, filterStatus } = values;

    if(!selectedRoles?.length && !selectedSortBy && !selectedSortOrder && filterStatus === "ACTIVE" && isfilterArchive === "ACTIVE"){
      setIsClearAllDisabled({...isClearAllDisabled, applyButton: true, clearBtn: true});
      onFilterChange(false);
    }
  },[isfilterArchive,page])

  return (
    <Modal
      footer={null}
      title={
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            color: 'rgb(61, 124, 183)',
            textDecoration: 'underline'
          }}
        >
          FILTER CONTACTS
        </span>
      }
      open={isSearchModalOpen}
      onCancel={handleModalCancel}
    >
      <Form
        form={form}
        initialValues={{
          filterStatus: "ACTIVE",
        }}
        onValuesChange={() => setIsClearAllDisabled(false)}
      >
        <div className={styles.filterModal}>
          <div>
            <h4>Status</h4>
            <Form.Item name="filterStatus">
              <Select
                style={{ width: "100%" }}
                placeholder="Select Status"
                options={[
                  { value: 'ACTIVE', label: 'Active' },
                  { value: 'ARCHIVE', label: 'Archived' },
                ]}
              />
            </Form.Item>
          </div>

          <div>
            <h4>Hashtag</h4>
            <Form.Item name="selectedRoles">
              <Select
                style={{ width: "100%" }}
                mode="multiple"
                placeholder="Select Hashtag"
              >
                {roleHashTags?.map((role) => (
                  <Select.Option key={role} value={role}>
                    {role}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <div>
            <h4>Sort By</h4>
            <Form.Item name="selectedSortBy">
              <Select
                style={{ width: "100%" }}
                placeholder="Select sorting criteria"
              >
                <Select.Option value="createdAt">Created At</Select.Option>
                <Select.Option value="updatedAt">Updated At</Select.Option>
                <Select.Option value="fullName">Full Name</Select.Option>
              </Select>
            </Form.Item>
          </div>

          <div>
            <h4>Order</h4>
            <Form.Item name="selectedSortOrder">
              <Select
                style={{ width: "100%" }}
                placeholder="Select sorting order"
              >
                <Select.Option value="ASC">Ascending</Select.Option>
                <Select.Option value="DESC">Descending</Select.Option>
              </Select>
            </Form.Item>
          </div>
        </div>

        <div style={{ marginTop: '16px', textAlign: 'right' }}>
          <hr />
          <Button
            style={{ marginLeft: "10px" }}
            type="primary"
            onClick={handleClearAllButtonClick}
            disabled={isClearAllDisabled?.clearBtn}
          >
            Clear All
          </Button>
          <Button
            style={{ marginLeft: "10px" }}
            type="primary"
            onClick={handleApplyButtonClick}
            disabled={isClearAllDisabled?.applyButton}
          >
            Apply
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export { FilterClients };
