import { clientConstants } from "../action-types";

const initialState = {
  globalClientLoading: false,

  documentryListDetails: [],
  documentryListLoading: false,
  documentryListError: null,

  draftDocumentData: [],
  draftDocumentLoading: false,
  draftDocumentError: null,
  currentDisableData: [],
};
const documentryReducer = (state = initialState, action) => {
  switch (action.type) {
    // Documentry List
    case clientConstants.DOCUMENTRY_LIST_LOADING:
      return {
        ...state,
        documentryListDetails: action.documentryListDetails,
        documentryListLoading: true,
      };
    case clientConstants.DOCUMENTRY_LIST_SUCCESS:
      return {
        ...state,
        documentryListDetails: action.documentryListDetails,
        documentryListLoading: false,
      };
    case clientConstants.DOCUMENTRY_LIST_FAILURE:
      return {
        ...state,
        documentryListError: action.documentryListError,
        documentryListLoading: false,
      };
    // url shortning
    case clientConstants.URL_SHORTNING_DRAFT_DOCUMENT_SUCCESS:
      return {
        ...state,
        draftDocumentData: action.draftDocumentData,
        globalClientLoading: false,
        draftDocumentLoading: false,
        draftDocumentError: null,
      };
    case clientConstants.URL_SHORTNING_DRAFT_DOCUMEN_FAILURE:
      return {
        ...state,
        draftDocumentData: [],
        draftDocumentLoading: false,
        globalClientLoading: false,
        draftDocumentError: null,
      };
    default:
      return state;
  }
};
export { documentryReducer };
