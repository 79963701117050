import { Button } from "antd";
import styles from "../styles/ZipUpload.module.css"

const Pagination = ({ safeTDriveTotalData, pageNumberInZip, handlePageChange, goToPreviousPage, goToNextPage }) => {
    const totalPages = Math.ceil(safeTDriveTotalData / 25);
    const visiblePages = 2;
  
    const getPageNumbers = () => {
      let pages = [];
      pages.push(1);
      if (pageNumberInZip > visiblePages + 2) {
        pages.push("...");
      }
      for (let i = Math.max(2, pageNumberInZip - visiblePages);i <= Math.min(totalPages - 1, pageNumberInZip + visiblePages);i++) {
        pages.push(i);
      }
      if (pageNumberInZip < totalPages - visiblePages - 1) {
        pages.push("...");
      }
      if (totalPages > 1) {
        pages.push(totalPages);
      }
  
      return pages;
    };
  
    const pageNumbers = getPageNumbers();
  
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "10px",
          alignItems: "center",
        }}
        className={styles.paginationContainer}
      >
        {/* Previous Button */}
        {safeTDriveTotalData && <button disabled={pageNumberInZip === 1}
          onClick={goToPreviousPage}
          style={{
            cursor: pageNumberInZip === 1 ? "auto" : "pointer",

            fontWeight: "bold", fontSize: "16px"
          }}
        >
          {'<'}
        </button>}
  
        {/* Page Numbers */}
        {pageNumbers?.map((page, index) =>
          page === "..." ? (
            <span key={index} style={{ fontWeight: "bold", fontSize: "16px" }}>
              ...
            </span>
          ) : (
            <Button
              type="primary"
              key={index}
              disabled={pageNumberInZip === page}
              onClick={() => handlePageChange(page)}
              style={{
                borderRadius: "5px",
                backgroundColor: pageNumberInZip === page ? "#096dd9" : "white",
                color: pageNumberInZip === page ? "white" : "black",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              {page}
            </Button>
          )
        )}
  
        {/* Next Button */}
        {safeTDriveTotalData &&  <button
          disabled={pageNumberInZip === totalPages}
          onClick={goToNextPage}
          style={{
            cursor: pageNumberInZip === totalPages ? "auto" : "pointer",
            fontWeight: "bold", fontSize: "16px",
          }}
        >
          {'>'}
        </button> }
      </div>
    );
  };
 
  export default Pagination