/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { List, Avatar, Switch, Input, Typography, Form } from "antd";
import {
  PlusCircleFilled,
  UserOutlined,
  SearchOutlined,
  FilterOutlined,
  FilterFilled,
  CheckCircleFilled
} from "@ant-design/icons";
import "../styles/client.css";
import styles from "../styles/Client.module.css";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { CreateCustomerDrawer } from "../../DocumentSection/components/CreateCustomerDrawer";
import { documentAction } from "../../DocumentSection/state/actions";
import { generateFullNameFromObj, generateInitialsFromObj} from "../../Common/utils/extraFunctions";
import { Loading } from "../../Common/components/Loading";
import { FilterClients } from "./FilterClients";
import { clientAction } from "../state/actions";
import { Email } from "./Email";
import { SaveCancelModal } from "./SaveCancelModal";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
const { Title,Text } = Typography;
var timeoutId;
function ClientList({
  selectedClient,
  selectClientHandle,
  setGetClientDetail,
  setSelectedClient,
  setNewRedirectClientToOffer,
  listSource,
  selectClient,
  personId,
  page,
  setPage,
  setShowSearchInput,
  showSearchInput,
  value,
  setValue,
  setPersonId,
  queryClientId,
  clientsArray,
  isCorpTrue,
  currentlySelectedCorpData,
  delegateOwnerId,
  setIsFilterArchive,
  isfilterArchive,
}) {
  const limit = 50;
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [clientType, setClientType] = useState("all");
  const [click, setClick] = useState(false);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [wait, setWait] = useState(true);
  const selectedClientRef = useRef(selectedClient);
  const [isClearAllDisabled, setIsClearAllDisabled] = useState({
    applyButton: true,
    clearBtn: true
  });
  const [filterNotApplied, setfilterNotApplied] = useState(true);
  const location = useLocation()
  const history = useHistory();
  const [form] = Form.useForm();

  const { getClientData, getClientLoading, totalCount } = useSelector(
    (state) => state.client.clientReducer,
    shallowEqual
  );
  const { personDetails } = useSelector(
    (state) => state.client.personDetailReducer,
    shallowEqual
  );

  useEffect(()=>{
    dispatch(clientAction.clearGetClientData());
  },[])

  let unmounted = {
    value: false
  };
  let source = axios.CancelToken.source();
  useEffect(() => {
    const values = form?.getFieldsValue() || {};
    const { selectedRoles, selectedSortBy, selectedSortOrder, filterStatus } = values;

    if(setIsFilterArchive) setIsFilterArchive(filterStatus);

    dispatch(clientAction.getClientList({value: value?.trim(), section: filterStatus || "ACTIVE", page: page[0], limit, selectedSortBy, selectedSortOrder, selectedRoles, personId, setSelectedClient, delegateOwnerId,selectedClient, source, unmounted}));
    return () => {
      unmounted.value = true;
      source.cancel("Cancelling in cleanup");
    }
  }, [ page ]);

  const [modelDrawer, setModelDrawer] = useState({
    status: false,
    component: "",
    title: "",
  });

  const showSearchModal = () => {
    setIsSearchModalOpen(true);
  };

  const handleSearchCancel = (handleClearAllButtonClick) => {
    setIsSearchModalOpen(false);
    if(handleClearAllButtonClick){
        handleClearAllButtonClick();
    }
  };

  useEffect(() => {
    if (!selectedClient && getClientData?.length > 0 && !listSource) {
      setSelectedClient(getClientData[0]?._id);
      setNewRedirectClientToOffer(getClientData?.[0]);
    }
  }, [getClientData]);

  let drawerClose = () => {
    setDrawerVisible(false);
    dispatch(documentAction.clearCustomerData());
    dispatch(documentAction.resetCreateCustomer());
  };
  const handleDynamicBg = () => {
    setClick(!click);
  };

  // logic for handling the client when  we toggle the switch button of active and non-active clients
  const changeClientType = (checked) => {
    dispatch(clientAction.clearGetClientData());
    if (listSource === "calendar" || listSource === "offerSection") {
      if (checked) {
        setClientType("active");
      }
      if (!checked) {
        setClientType("all");
      }
    } else {
      setPersonId("");
      if (checked) {
        setClientType("active");
      }
      if (!checked) {
        setClientType("all");
      }
      setSelectedClient("")
      setNewRedirectClientToOffer("");
    }
    setPage([1]);
  };

  const searchClientFunc = (value) => {
    setValue(value);
    if(!listSource){
      setPersonId("");
      if (queryClientId) {
        history.push("/contacts");
      }
    }
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      dispatch(clientAction.clearGetClientData());
      setPage([1]);
      if(!listSource){
        setSelectedClient("");
        setNewRedirectClientToOffer("");
      }
    }, 1000);
  };

const handleFilterChange = (filterApplied) => {
  if(!listSource){
    setSelectedClient("");
    setNewRedirectClientToOffer("");
  }
  setFiltersApplied(filterApplied);
};

const handleCreateRender = (id, fullName) => {
  dispatch(clientAction.clearGetClientData());
  setSelectedClient("");
  setClientType("all");
  setNewRedirectClientToOffer("");
  if(!listSource){
    setPersonId(id)
  }
  setPage([1]);
}

const onFinishEmail = (values) => {
  if(setPersonId){
    setPersonId(selectedClientRef.current);
  }
  dispatch(clientAction.updateEmail(values, personDetails, selectedClientRef.current , setPage, setSelectedClient,listSource));
  setModelDrawer({ status: false, component: "", title: "" });
};

useEffect(() => {
  selectedClientRef.current = selectedClient;
}, [selectedClient]);


useEffect(() => {
  if (showSearchInput && !getClientLoading) {
    inputRef.current.focus();
  }
}, [showSearchInput,getClientLoading]);

  return (
    <>
      {modelDrawer.status && (
        <SaveCancelModal
          modelDrawer={modelDrawer}
          setModelDrawer={setModelDrawer}
        />
      )}
     <FilterClients 
        setPage={setPage}
        filterNotApplied={filterNotApplied} 
        setfilterNotApplied={setfilterNotApplied}
        setIsSearchModalOpen={setIsSearchModalOpen} 
        setPersonId={setPersonId} 
        listSource={listSource}
        isClearAllDisabled={isClearAllDisabled} 
        setIsClearAllDisabled={setIsClearAllDisabled} 
        handleSearchCancel={handleSearchCancel} 
        isSearchModalOpen={isSearchModalOpen} 
        onFilterChange={handleFilterChange}
        form={form}
        isfilterArchive={isfilterArchive}
        page={page}
      />
      <CreateCustomerDrawer
        visible={drawerVisible}
        onClose={drawerClose}
        nameData={"contact"}
        handleCreateRender={handleCreateRender}
        delegateOwnerId={delegateOwnerId}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <div>
          <SearchOutlined
            style={{ fontSize: "30px", color: "#085191" }}
            onClick={() => setShowSearchInput(!showSearchInput)}
          />
        </div>
        <div style={{ textAlign: "center" }}>
          <Title level={3}>
            {/* {clientType === "all" ? "All" : "Active"}  */}
            Contacts
          </Title>
        </div>
        <div style={{display:"flex",gap:"10px"}}>
          {listSource === "calendar" || (listSource === "offerSection") ? null : (
            <PlusCircleFilled
              onClick={() => {
                setDrawerVisible(true)
                // setshowModal(false)
              }}
              style={{ fontSize: "30px", color: "#085191" }}
            />
          )}
          {/* <Switch
            style={{ marginBottom: "15px", marginLeft: "10px" }}
            onChange={changeClientType}
            checked={clientType === "all" ? false : true}
          /> */}
          {filtersApplied?<FilterFilled  onClick={showSearchModal} style={{fontSize:"25px",marginTop:"3px",cursor:"pointer"}} />:
          <FilterOutlined  onClick={showSearchModal} style={{fontSize:"25px",marginTop:"3px",cursor:"pointer"}}/>}
        </div>
      </div>
      <div>
        {showSearchInput && (
        <div style={{display:"flex",textAlign:"center"}}>
          <Input
            ref={inputRef}
            onChange={(e)=>searchClientFunc(e.target.value)}
            value={value}
            placeholder="Search a Client"
            autoFocus={true}
            allowClear
            style={{
              height: "40px",
              border: "1px solid #085191",
              fontSize: "20px",
              marginRight:"10px"
            }}
          />
        </div>
        )}
      </div>

      <div
        className={
          showSearchInput
            ? "mozScrollbar clientListBox"
            : "mozScrollbar clientListBoxForSmallScreen"
        }
        id="parent-scroll-div"
        style={{
          overflowY: "auto",
          overflowX: "hidden",
        }}
        onScroll={(e)=>{
            let currentHeight = e.target.scrollHeight - e.target.clientHeight
            if(e.target.scrollTop>=(currentHeight-150)){
              if(!getClientLoading && wait && ((totalCount !== getClientData.length) || (totalCount > getClientData.length))){
                setPage([page[0] + 1]);
                setWait(false);
              }
            }
            setTimeout(()=>{
              setWait(true);
            },2000)
        }}
      >
        {getClientLoading && (page[0]===1) ? (
          <div style={{ marginTop: "65%" }}>
            <Loading />
          </div>
        ) : (
          <>
            <List
              className={styles.demoloadlisting}
              bordered={true}
              itemLayout="horizontal"
              dataSource={
                getClientData || []
              }
                renderItem={(item) => {
                  const intials = generateInitialsFromObj(item);
                return (
                  <List.Item
                    className={[styles.clientListItem, styles.listuser]}
                    style={{
                      background: item._id === selectedClient ? "#92b3d1" : "white",
                    }}
                    key={item._id}
                    hoverable
                    onMouseEnter={(e) => {
                      e.currentTarget.style.background = "#a8c5dc";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.background = item._id === selectedClient ? "#92b3d1" : "white";
                    }}
                    onClick={() => {
                      if (listSource === "calendar") {
                        selectedClientRef.current = item?._id
                        selectClient(item);
                      } else if (listSource === "offerSection") {
                        selectedClientRef.current = item?._id
                        selectClient(item);
                      } else {
                        if (queryClientId) {
                          history.push("/contacts");
                        }
                        setSelectedClient(item._id);
                        selectClientHandle(item);
                        setGetClientDetail(item);
                        handleDynamicBg();
                      }
                    }}
                  >
                    <List.Item.Meta
                      avatar={
                        item.profileImg && item.profileImg.original ? (
                          <Avatar
                            style={{
                              border: "3px solid #085191",
                              background: "#347FC0",
                            }}
                            size={55}
                            className={styles.useravtar}
                            src={item.profileImg.original}
                          />
                        ) : intials ? (
                          <Avatar size={55} style={{ background: "#085191" }}>
                            {intials}
                          </Avatar>
                        ) : (
                          <Avatar size={55} icon={<UserOutlined />} style={{ background: "#085191" }} />
                        )
                      }
                      title={<span style={{ fontSize: "20px" }}>{generateFullNameFromObj(item)}</span>}
                      description={item.email ? <Text ellipsis>{item.email}</Text> : <Email source={"transaction"} selectedClient={item._id} personDetails={item} setModelDrawer={setModelDrawer} setshowModal={true} onFinish={onFinishEmail}/>}
                    />
                    <div>
                        {
                          !isCorpTrue ?
                          clientsArray && clientsArray?.map((el,ind)=>{
                              return <div>
                                  {
                                      el?.personId === item?._id && <CheckCircleFilled style={{ fontSize: "18px", backgroundColor: "white", borderRadius: "100px", color: "green" }} />
                                  }
                              </div>
                          })
                          :
                          currentlySelectedCorpData && currentlySelectedCorpData?.signers?.map((el,ind)=>{
                            return <div>
                                {
                                    el?.personId === item?._id && <CheckCircleFilled style={{ fontSize: "18px", backgroundColor: "white", borderRadius: "100px", color: "green" }} />
                                }
                            </div>
                          })
                        }
                    </div>
                  </List.Item>
                );
              }}
            />
            <div style={{height:50}}>
                {getClientLoading  && <Loading />}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export { ClientList };
