import React, { useEffect, useRef, useState } from "react";
import { List, Avatar, Modal, Menu, Dropdown, Button, Tooltip, message, Typography, Spin } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { MoreOutlined, EyeOutlined, EditOutlined, DeleteTwoTone, ShareAltOutlined, CopyOutlined, MailOutlined } from "@ant-design/icons";
import { CgTemplate } from "react-icons/cg";
import "../styles/Template.css";
import { useDispatch, useSelector } from "react-redux";
import { templateAction } from "../state/actions";
import styles from "../styles/Template.module.css";
import ReactQuill from "react-quill";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
const { confirm } = Modal;
const { Text } = Typography;

function EachCard({ data }) {
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [templateData, setTemplateData] = useState({
    name: "",
    description: "",
  });
  const [templateName,setTemplateName] = useState("")
  const [tempData, setTempData] = useState();
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const inputRef = useRef()
  const location = useLocation();
  const { deleteTemplateLoading } = useSelector(
    (state) => state.template
  );
  const { templateUrl } = useSelector((state) => state.template);
  const {  userId } = useSelector((state) => state.account);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  let handleDelete = (id) => {
    dispatch(templateAction.deleteTemplate(id));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setTemplateName(value)
    setTemplateData({ ...templateData, [name]: templateName });
  };
  const handleChangedescription=(value)=>{
    setTemplateData({ ...templateData, description: value });
  }
 

  const showDeleteConfirm = (id) => {
    confirm({
      title: "Are you sure you want to delete this template?",
      icon: <ExclamationCircleOutlined />,
      // content: "Once you deleted, Record can not be restored !!!",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(id);
      },
      onCancel() {},
    });
  };

  let handleMenu = (event) => {
    switch (event.key) {
      case "item_0":
        // window.location = `http://3.139.212.52:3000/${data[0].contractId._id}/${data[0]._id}?mobileToken=${userAuth}`;
        break;
      case "item_1":
        // window.location = `http://3.139.212.52:3000/editContract/${data[0].contractId._id}/${data[0]._id}?mobileToken=${userAuth}&offerType=TEMPLATE`;
        break;
      case "item_3":
        // showDeleteConfirm(event.item.props.id);
        break;
      case "item_4":
        // window.location = `http://localhost:3000/contract/${data[0].contractId._id}?buyerAgentId=${event.item.props.data.buyerAgentId}&mobileToken=${userAuth}&offerType=TEMPLATE`;
        break;
      default:
        break;
    }
  };

  const handleTempalteOptions = (props, createOrEditTemplate, openFor, redirect) => {
    let buildData = {
      builtForId: userId,
      builtForSection: "TEMPLATE",
      signable:createOrEditTemplate ? true: false,
      openFor: openFor,
      contractType: props?.item?.contractType,
      token: localStorage.getItem("userAuth"),
      contractId: props?.item?.contractId?._id,
      documentId: props?.item?._id,
      createOrEditTemplate: createOrEditTemplate,
    };
    dispatch(templateAction.templateOffer(buildData,location?.pathname, redirect));
  };

  const MenuItem = (props) => {
    return (
      <Menu onClick={handleMenu}>
        <Menu.Item
          icon={
            <EyeOutlined
              style={{
                color: "hsl(211deg 77% 48%)",
              }}
            />
          }
          onClick={()=> handleTempalteOptions(props, false, "BUYER_AGENT", true)}
        >
          View
        </Menu.Item>
        <Menu.Item
          icon={
            <EditOutlined
              style={{
                color: "hsl(211deg 77% 48%)",
              }}
            />
          }
          onClick={() => handleTempalteOptions(props, true,"BUYER_AGENT", true)}
        >
          Edit
        </Menu.Item>
        <Menu.Item
          icon={
            <ShareAltOutlined
              style={{
                color: "hsl(211deg 77% 48%)",
              }}
            />
          }
          onClick={() => {
            handleTempalteOptions(props, false, "BUYER", false);
            setShareModalOpen(true);
          }}
        >
          Share
        </Menu.Item>
        <Menu.Item
          id={props.item._id}
          icon={
            <DeleteTwoTone
              style={{
                color: "hsl(0deg 82% 64%)",
              }}
            />
          }
          onClick={() => deleteTemplate1(props.item)}
        >
          Delete
        </Menu.Item>
      </Menu>
    );
  };

  const saveTemplateData = () => {
    if (!templateName) {
      return;
    }
    let payloadData = {
      templateName: templateName,
      templateDescription: templateData.description,
      documentId:tempData?._id
    };
    dispatch(templateAction.updateTemplateEditData(payloadData));
    handleCancel();
  };

  const deleteTemplate1 = (tData) => {
    showDeleteConfirm(tData._id);
    handleCancel();
  };

  const quillRef = useRef();

  const handleInsertFirstName = () => {
    const quill = quillRef.current.getEditor();
    const cursorPosition = quill.getSelection().index;
// Insert the desired text at the cursor position
    quill.insertText(cursorPosition, "${CUST_F_NAME}");

  };

  const handleInsertLastName = () => {
    const quill = quillRef.current.getEditor();
    const cursorPosition = quill.getSelection().index;
 // Insert the desired text at the cursor position
    quill.insertText(cursorPosition, "${CUST_L_NAME}");

  };

  const handleInsertPropertyAddress = () => {
    const quill = quillRef.current.getEditor();
    const cursorPosition = quill.getSelection().index;
  // Insert the desired text at the cursor position
    quill.insertText(cursorPosition, "${PROP_ADDRESS}");

  };

    const handleInsertDate = () => {
    const quill = quillRef.current.getEditor();
    const cursorPosition = quill.getSelection().index;
   // Insert the desired text at the cursor position
    quill.insertText(cursorPosition, "${CURR_DATE}");
  };
  const handleInsertTime = () => {
    const quill = quillRef.current.getEditor();
    const cursorPosition = quill.getSelection().index;

    // Insert the desired text at the cursor position
    quill.insertText(cursorPosition, "${CURR_TIME}");

  };
    const handleInsertMiddleName = () => {
    const quill = quillRef.current.getEditor();
    const cursorPosition = quill.getSelection().index;
  // Insert the desired text at the cursor position
    quill.insertText(cursorPosition, "${CUST_M_NAME}");

  };

  useEffect(() => {
    if (isModalVisible) {
      const focusTimeout = setTimeout(() => {
        inputRef.current && inputRef.current.focus();
      },100);

      return () => {
        clearTimeout(focusTimeout);
      };
    }
  }, [isModalVisible]);

  return (
    <>
      {deleteTemplateLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: "50%",
            left: "50%",
            zIndex: 10000,
          }}
        >
          <Spin />
        </div>
      )}
      {data && data.length > 0 ? (
        <List
          itemLayout="vertical"
          grid={{
            gutter: 2,
            xs: 2,
            sm: 2,
            md: 2,
            lg: 2,
            xl: 3,
            xxl: 4,
          }}
          style={{
            height: "calc(100vh - 240px)",
            overflowY: "auto",
            overflowX: "hidden",
          }}
          dataSource={data}
          className="templateBlocks moxScrllbar "
          renderItem={(item) => {
            return (
              <div
                className={styles.templateIndivisualBox}
                style={{
                  margin: "7px 17px 7px 17px",
                  padding: "1px",
                  borderRadius: "5px",
                  boxShadow: "1px 1px 5px #888888",
                  height: "150px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <List.Item key={item.id}>
                  <div
                    style={{
                      color: "hsl(211deg 77% 48%)",
                      fontWeight: "bolder",
                      fontSize: "18px",
                      padding:"20px",
                      // marginBottom:"-20px",
                      position: "absolute",
                      top: 16,
                      right: 5,
                    }}
                  >
                    <Dropdown overlay={<MenuItem item={item} />}>
                      <MoreOutlined
                        key="ellipsis"
                        style={{ position: "relative",left:"15px",top:"-15px" }}
                      />
                    </Dropdown>
                  </div>
                  <List.Item.Meta
                    onClick={() => {
                      item?.templateName ? setTemplateName(item?.templateName) : setTemplateName("");
                      return (
                        showModal(),
                        setTemplateData({
                          name: item.templateName || "",
                          description: item.templateDescription || "",
                        }),
                        setTempData(item)
                      );
                    }}
                    avatar={
                      <Avatar
                        size={{
                          xs: 70,
                          sm: 70,
                          md: 70,
                          lg: 70,
                          xl: 70,
                          xxl: 80,
                        }}
                        shape="round"
                        style={{
                          background: "transparent",
                          color: "hsl(211deg 77% 48%)",
                          width: "50px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "80px",
                          marginTop: 0,
                        }}
                        icon={
                          <CgTemplate
                            style={{
                              color: "#085191",
                              fontSize: "40px",
                              textAlign: "center",
                              marginTop: "20px",
                            }}
                          />
                        }
                      />
                    }
                    title={
                      <Text
                        ellipsis={{
                          rows: 2,
                          expandable: false,
                          tooltip: true,
                        }}
                        className={styles.templateTitle}
                        style={{
                          width: "90%",
                          color: "grey",
                          marginLeft: "-10px",
                          marginTop: "30px",

                          marginBottom: "-6px",
                        }}
                      >
                        {item.templateName ? item.templateName : "Test"}
                      </Text>
                    }
                    description={

                      <div
                       className={styles.tempDescription}
                                                          style={{
                          height: "100px",
                          width: "100%",
                          overflow: "auto",
                          color: "grey",
                          marginBottom: 0,
                          // border: "2px solid red",
                        }}
  dangerouslySetInnerHTML={{
    __html: item.templateDescription
      ? item.templateDescription
      : `Description regarding ${
          item.hasOwnProperty("templateName") ? item?.templateName : ""
        } template`,
  }}
/>
                    }
                  />
                </List.Item>
              </div>
            );
          }}
        />
      ) : (
        <Spin />
      )}

      <Modal
        title="Edit Template Details"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
              type="primary"
                style={{ width: "70px", height: "35px",fontSize:"15px" }}
                onClick={saveTemplateData}
              >
                Save
              </Button>
            </div>,
        ]}
      >
        <div style={{  }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {/* <label>Template Name</label> */}
            <input
              ref={inputRef}
              value={templateName}
              name="name"
              autoFocus
              onChange={handleChange}
              // style={{ width: "50%" }}
              className="newNumberInput"
              placeholder="Name"
            />
          </div>
          {!templateName? (
            <Text type="danger">Template name cannot be empty</Text>
          ) : (
            ""
          )}
          <div
            style={{

              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >

          <ReactQuill
            value={templateData.description}
            style={{ height: "200px", marginBottom: "100px", maxWidth: "500px" }}
            ref={quillRef}
            placeholder="Description"
            onChange={ handleChangedescription}
            modules={{
              toolbar: [
                ["bold", "italic", "underline", "strike"], // toggled buttons
                ["blockquote", "code-block"],
                [{ header: 1 }, { header: 2 }], // custom button values
                [{ list: "ordered" }, { list: "bullet" }],
                [{ script: "sub" }, { script: "super" }], // superscript/subscript
                [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                [{ direction: "rtl" }], // text direction
                [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                [{ font: [] }],
                [{ align: [] }],
                ["clean"],// remove formatting button
              ],

            }}
          />
          </div>
        </div>
      </Modal>

      <Modal
        title="Share Template"
        visible={shareModalOpen}
        onOk={handleOk}
        onCancel={() => setShareModalOpen(false)}
        footer={[
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Tooltip title="Copy">
              <Button
                style={{ width: "80px", height: "40px" }}
                onClick={() => {
                  message.success("Copied!");
                  navigator.clipboard.writeText(templateUrl);
                }}
              >
                <CopyOutlined style={{ fontSize: "28px" }} />
              </Button>
            </Tooltip>
            
            <Tooltip title="Email">
              <Button
                style={{ width: "80px", height: "40px",marginLeft:"5px" }}
                onClick={() => {}}
                disabled
              >
                <MailOutlined style={{ fontSize: "28px" }} />
              </Button>
            </Tooltip>
            
          </div>,
        ]}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Tooltip title="Copy">
              <input
                value={templateUrl}
                className="newNumberInput"
                readonly="readonly"
                onClick={() => {
                  message.success("Copied!");
                  navigator.clipboard.writeText(templateUrl);
                }}
                style={{ cursor: "pointer" }}
              />
            </Tooltip>
          </div>
        </div>
      </Modal>
    </>
  );
}
export { EachCard };
