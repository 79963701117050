/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import {  Collapse,  message,  Spin, } from 'antd';
import { CaretRightOutlined } from "@ant-design/icons"
import ProfileDetails from './ProfileDetails';
import PanelComp from './PanelComp';
import { PropertyHandlers } from '../state/actions';
import { useDispatch, useSelector } from 'react-redux';
import styles from "../styles/profileDetails.module.css";
import PropertyNavbar from './PropertyNavbar';
import axios from 'axios';

import { useLocation, useHistory } from "react-router-dom";

// section ------>
// LIMITED_TRANSACTION -> DELAGATE LIMITED TRANSACTION SEARCH
// DELEGATE -> DELEGATE TRANSACTION SECTION

const  Transactions = ({section, setSelectedTransaction, selectedTransaction, readOnly, isDelegateHasLimitedAccess}) => {
  const delegateAndLimited = (section === "DELEGATE") || (section === "LIMITED_TRANSACTION");
  const limited =  ((section === "LIMITED_TRANSACTION") || (section === "HOME"));
  const delegate =  (section === "DELEGATE");
  const home = ( section === "HOME" );

  const dispatch = useDispatch();
  const { state, pathname } = useLocation();
  const history = useHistory();
  const { Panel } = Collapse;
  const [activeKey, setActiveKey] = useState("0");
  const { propertyData, propertyDataLoading, propertyDataError, } = useSelector(state=>state.PropertyReducer);
  const [selectedPropertyId, setSelectedPropertyId] = useState(null);
  const [count, setCount] = useState({});
  const query = new URLSearchParams(window.location?.search);
  const status = query?.get("status");
  const {filterSort} = useSelector((state) => state.PropertyReducer);
  const hasMoreDataRef = useRef(true);
  const [pageNumber, setPageNumber] = useState(1)
  let dataPerPage = home ? 6: 20;
  
  useEffect(() => {
    if ((status || state?.status) && !filterSort?.statusSort?.includes(status || state?.status)) {
      dispatch(PropertyHandlers.changeFilterSort({
        ...filterSort,
        statusSort: [...filterSort?.statusSort, status || state?.status]
      }));
    }
  }, [status, state])

  useEffect(() => {
    setPageNumber(1);
    hasMoreDataRef.current = false;
  }, [filterSort, state]);

  let unmounted = {
    value: false
  };
  let sourceAxios = axios.CancelToken.source();
  useEffect(() => {
    const fetchData = async () => {
      try {
        let category = query?.get("category");
        let categoryId = query?.get("categoryId");
        if (!(category && categoryId)) {
          const highlightCheck = ((count?.categoryId !== state?.categoryId) || (count?.category !== state?.category));
          const response = await dispatch(PropertyHandlers.getPropertyData({
            type: delegate ? "DELEGATE_TRANSACTIONS" : "TRANSACTIONS",
            filterSort,
            unmounted,
            sourceAxios,
            category: highlightCheck ? state?.category : "",
            categoryId: highlightCheck ? state?.categoryId : "",
            readOnly,
            pageNumber,
            pageSize: dataPerPage
          }));

          if (state?.category || state?.categoryId) {
            setCount({
              ...state
            });
          }
          if (response?.data?.info?.transactions?.length < dataPerPage) {
            hasMoreDataRef.current = false;
          } else {
            hasMoreDataRef.current = true;
          }
        }
        else {
          history.push({
            pathname: pathname,
            state: {
              category: category, categoryId: categoryId
            }
          })
        }

        setActiveKey("0");
        window.history.replaceState({}, document.title);
      } catch (error) {
        message.error(error?.response?.data?.message || "An error occurred while getting property data!");
      }
    };

    fetchData();

    return () => {
      unmounted.value = true;
      sourceAxios.cancel("Cancelling in cleanup");
    }
  }, [filterSort, state, pageNumber])

  const handleChange = (key) => {
    setActiveKey(key);
  };

  // Infinite scroll handler

  const handleScroll = (e) => {
    const scrollableDiv = e.target;
    const { scrollTop, scrollHeight, clientHeight } = scrollableDiv;
    const finalScrollHeight = !section ? (scrollHeight - 20) : scrollHeight - 20;

    // initially in the 1st page if the data length is less than 20 then do not update the page and return
    if (pageNumber === 1 && propertyData?.length < dataPerPage) {
      hasMoreDataRef.current = false;
      return;
    }
    // Check if we've reached the bottom of the scrollable container
    if (scrollTop + clientHeight >= finalScrollHeight && !propertyDataLoading && hasMoreDataRef.current) {
      setPageNumber(pageNumber + 1)
    }
  };

  // Add addEventListener for infinite scrollbar
  useEffect(() => {
    const scrollableDiv = !section ? document.querySelector(`.${styles.transactionContainer}`) : document.querySelector(`.${styles.delegateContainer}`);
    if (scrollableDiv) {
      scrollableDiv.addEventListener('scroll', handleScroll);
    }

    // Cleanup on unmount
    return () => {
      if (scrollableDiv) {
        scrollableDiv.removeEventListener('scroll', handleScroll);
      }
    };
  }, [propertyDataLoading]);
  
  const handleCheckBox = (e, item) => {
    e.stopPropagation();
    let latestData = { ...selectedTransaction };
    if(item.isOffer){
      if(latestData?.buying?.includes(item.offerId)){
        latestData.buying =  latestData?.buying?.filter((ele)=> ele !== item.offerId)
      }
      else{
        latestData.buying = [ ...latestData.buying, item.offerId ]
      }
    }
    else if(item.isProperty){
      if(latestData?.sellingProperty?.includes(item.propertyId)){
        latestData.sellingProperty =  latestData?.sellingProperty?.filter((ele)=> ele !== item.propertyId)
      }
      else{
        latestData.sellingProperty = [ ...latestData.sellingProperty, item.propertyId ]
      }
    }
    setSelectedTransaction({
      ...latestData      
    })
  }

  const handleHomeRedirection = (item) => {
    if (typeof item !== "object") return;
    const category = item.isProperty ? "SELLER" : "BUYER";
    let categoryId = null;
    if (category === "SELLER") {
      categoryId = item.propertyId;
    } else {
      categoryId = item.offerId || null;
    }
    history.push({
      pathname: "/transactions",
      state: {
        category,
        categoryId,
      },
    });
  };
  

  return (
    <>
      {!home && <PropertyNavbar section={section}/>}
      <div className={home ? styles.homeTransactions : delegateAndLimited ? styles.delegateContainer : styles.transactionContainer} style={{ height: home && isDelegateHasLimitedAccess ? "60vh" : "" }}>
        { propertyDataLoading && pageNumber <=1 ? 
          <div style={{
            height: home ? "40vh" : "60vh",
            display:"flex",
            justifyContent:"center",
            alignItems:"center"
          }}>
            <Spin size='large'/>
          </div> : propertyDataError ? "" :
          <div>
            <Collapse
              accordion
              ghost
              expandIcon={
                ({ isActive }) =>
                <CaretRightOutlined style={{display:"none"}} rotate={isActive ? 90 : 0} />
                }
              onChange={handleChange}
              activeKey={activeKey}
            >
              {(propertyData)?.map((el, idx)=>(
                <Panel
                  className={`${styles.panelBox} ${!(readOnly?.value) && ((limited) && styles.allHighlightDiv)}`}
                  style={{
                    border:"1.5px solid #cacaca",
                    width:"100%",
                    margin:'auto',
                    borderRadius:"10px",
                    marginBottom: 10,
                    boxShadow: "3px 3px 3px #ccc",
                    background: (((activeKey == idx) && !limited) ? '#E7F5E4' : "#FFFFFF"),
                  }}
                  header={<ProfileDetails filterSort={filterSort} readOnly={readOnly} item={el} section={section} setSelectedPropertyId={setSelectedPropertyId} selectedTransaction={selectedTransaction} selectedPropertyId={selectedPropertyId}/>}
                  key={idx}
                  {...(!(readOnly?.value || readOnly?.home) ? ((limited) ? {onClick: (e)=>handleCheckBox(e, el)} : {}) : section === "HOME" ? {onClick: () => handleHomeRedirection(el)} : {})}
                >
                  { !limited && <PanelComp item={el} section={section} filterSort={filterSort}/> }
                </Panel>
              ))}
            </Collapse>

            {/* Loader for infinite scroll */}
              {propertyDataLoading && pageNumber > 1 && (
                <div style={{ display:"flex", justifyContent:"center", alignItems:"center", height: "10vh" }}>
                  <Spin size="medium" />
                </div>
              )}
          </div>
        }
      </div>
    </>

  )
}

export default Transactions
