import { Button, Menu, Modal, message } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { DeleteTwoTone, ExclamationCircleOutlined, SolutionOutlined, InboxOutlined } from "@ant-design/icons";
import Dragger from "antd/lib/upload/Dragger";
import { useLocation, useHistory } from "react-router-dom";
import { PropertyHandlers } from "../state/actions";
import { commonStatusFunc, createOfferUploadType } from "../../Common/commondata/commonData";
import styles from "../styles/statusLabel.module.css";

const { confirm } = Modal;

const defaultStatusKeys = ({item, delegate}) => {
  return {
    file: "",
    statusChangeFrom: "",
    transactionType: (item?.isProperty) ? "SELLING" : "BUYING",
    ...(item?.isProperty ? {propertyId: item.propertyId} : {offerId: item.offerId}),
    statusChangeTo: "",
    openModal: false,
    text: "",
    note: "",
    highlight: false,
    delegateSide: delegate && ((item?.isProperty) ? "SELLER" : "BUYER"),
    delegateOwnerId: delegate && item?.currentAgentId?._id,
    contractType: ""
  }
}

const PanelMenuItem = ({ item, currentStatus, actualStatus, filterSort, section }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const delegate = (section === "DELEGATE");
  const { pathname } = useLocation();
  const [allData, setAllData] = useState(defaultStatusKeys({item, delegate}));
  const previousCommonStatus = commonStatusFunc({key: (item?.isOffer ) ? actualStatus : item?.previousStatus});
  const commonStatus = commonStatusFunc({key: currentStatus});
  const agentDetails =  (item?.currentAgentId)

  const handleDeleteConfirm = (id) => {
    Modal.confirm({
      title: `Are you sure you want to delete this ${item?.isProperty ? "listing" : "offer"}?`,
      content: `Note: This action is irreversible. Deleting this ${item?.isProperty ? "listing" : "offer"} will permanently remove it from your account.`,
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        let data = {
          ...(delegate ? {delegateSide: (item.isProperty)? "SELLER" : "BUYER", delegateOwnerId: agentDetails?._id} : {}),
        }
        if ((item?.isProperty) && (item?.propertyStatus === "DRAFT" || item?.propertyStatus === "INPROGRESS")) {
          data.propertyId = id
        } else {
          data.offerId = id
        }
        dispatch(PropertyHandlers.deleteDraftOfferFun({ data, filterSort}));
      },
      onCancel() {},
    });
  };
  
  let handleCustome = (data) => {
    const reader = new FileReader();
    reader.readAsDataURL(data.file);
    reader.onload = () => {};
    reader.onerror = (error) => {
      return error;
    };
    setAllData({
      ...allData,
      file: data.file
    });
  };

  function beforeUpload(file) {
    let supportedFormats = file?.type;

    if (supportedFormats?.includes("pdf")) {
      const isPdf = file.type === "application/pdf";
      if (!isPdf) {
        message.error("You can only upload PDF file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        message.error("File must be smaller than 5MB!");
      }
      return isPdf && isLt2M;
    }
  }

  function bytesToSize(bytes) {
    let sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }

  const handelStatusChange = ({statusTo, statusFrom, highlight, text, note}) => {
    let flag = {
      ...allData
    }

    if(statusTo === "TERMINATED"){
      flag.offerId = item?.offerId;
      delete flag.propertyId;
    }

    setAllData({
      ...flag,
      statusChangeTo: statusTo,
      statusChangeFrom: statusFrom,
      openModal: true,
      highlight,
      text,
      note
    })
  }

  const handleCancel = () => {
    setAllData(defaultStatusKeys({item, delegate}));
  }

  const successFunc = ({highlight}) => {
    const state = {
      category: ( (item?.isProperty) ? "SELLER" : "BUYER" ),
      categoryId: ( item?.isProperty ? item.propertyId : item?.offerId )
    }
    history.push({
      pathname: pathname,
      ...(highlight ? {state}: {state: {}})
    })
  }

  const handleOfferOrCounter = ({contractType}) => {
    setAllData({
      ...allData,
      contractType
    });
  }

  const handleSave = () => {
    if(!allData?.file){
      return;
    }

    confirm({
      title: `${allData?.note}`,
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(PropertyHandlers?.porstStatusChangeFunc({data: allData, successFunc}));
      },
      onCancel() {},
    });
  }

  const handleConfirmStatus = ({statusTo, statusFrom, text, highlight}) => {
    confirm({
      title: `Are you sure you want to change the status to ${text}?`,
      content: `Note: Selected transaction would go into ${text?.toUpperCase()} section.`,
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(PropertyHandlers?.porstStatusChangeFunc({data: {...allData, statusChangeTo: statusTo, statusChangeFrom: statusFrom, highlight}, successFunc}));
      },
      onCancel() {},
    });
  }

  const handleCustomStatus = ({statusTo, statusFrom, title, content, highlight}) => {
    confirm({
      title: title || "",
      content :content|| "",
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        let flag = {
          ...allData
        }
        if(statusTo === "TERMINATED"){
          flag.offerId = item?.offerId;
          delete flag.propertyId;
        }
        dispatch(PropertyHandlers?.porstStatusChangeFunc({data: {...flag, statusChangeTo: statusTo, statusChangeFrom: statusFrom, highlight}, successFunc}));
      },
      onCancel() {},
    });
    
  }
  const archivedClosedActivePending = ( (commonStatus?.key === "ARCHIVED") || (commonStatus?.key === "CLOSED") || (commonStatus?.key === "ACTIVE") || (commonStatus?.key === "UNDER_CONTRACT") || (commonStatus?.key === "TERMINATED") );
  const archivedClosed = ( (commonStatus?.key === "ARCHIVED") || (commonStatus?.key === "CLOSED") );
  const pending = (commonStatus?.key === "UNDER_CONTRACT");

  return (
    <>
      <Modal
        open={allData?.openModal}
        onCancel={handleCancel}
        style={{ marginTop: "-50px"}}
        footer={false}
        width={700}
      >
        {
          (allData?.statusChangeTo === "ACCEPTED" && !allData?.contractType) ? <div>
            <div className={styles.offerHeader}>Please select type of document you want to upload</div>
            <div className={styles.offerType}>
              {
                createOfferUploadType?.map((ele)=>{
                  return <div onClick={()=>handleOfferOrCounter({contractType: ele?.contractType})}>• {ele?.contractName}</div>
                })
              }
            </div>
          </div> : <div style={{minHeight: "350px"}}>
            <div style={{textAlign: "center", fontSize: "1.2rem", padding: "0px 0px 30px 0px"}}>{allData?.text}</div>
            <Dragger
              accept=".pdf"
              progress={false}
              showUploadList={false}
              customRequest={handleCustome}
              beforeUpload={beforeUpload}
            >
              <p className="ant-upload-drag-icon">
                  <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                  Paste, Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                  Support for a single document/pdf upload.
              </p>
            </Dragger>
            <div style={{textAlign:"center",marginTop:"20px"}}>
              {allData?.file?.name && <h3>{allData?.file?.name}</h3>}
              {allData?.file?.size && <h6>Size:{" "}{bytesToSize(allData?.file?.size )}</h6>}
            </div>
            <div style={{display: "flex", justifyContent: "center"}}><Button type="primary" disabled={!allData?.file} onClick={handleSave}>Save</Button></div>
          </div>
        }
      </Modal>
      <Menu size="large">
        {
          !archivedClosedActivePending && <Menu.Item
            onClick={() => {
              handleDeleteConfirm(item?._id);
                  }}
                  key="deleteOfferAndListings"
          >
            <DeleteTwoTone
              style={{
                fontSize: 16,
              }}
            />
            <span style={{ color: "04396B" }}> {item?.isProperty ? "Delete Listing" : "Delete Offer"}</span>
          </Menu.Item>
        }
        
        {
          !archivedClosedActivePending && item?.isProperty && ((item?.propertyStatus === "DRAFT") || (item?.propertyStatus === "INPROGRESS")) && <Menu.Item
            onClick={() => handelStatusChange({statusTo: "Active", statusFrom: actualStatus, highlight: false, text: "Please upload completely signed Exclusive Right to Sell PDF", note: "Are you sure you want to change the status to active?"})}
            key="changeToActive"
          >
            <SolutionOutlined
              style={{
                fontSize: 16,
                color: "#2885DC"
              }}
            />
            <span> Make Active</span>
          </Menu.Item>
        }
        {
          !archivedClosed && <Menu.Item
            onClick={() => handleConfirmStatus({statusTo: "ARCHIVED", statusFrom: actualStatus, text: "archived", highlight: false})}
            key="changeToArchive"
          >
            <SolutionOutlined
              style={{
                fontSize: 16,
                color: "#2885DC"
              }}
            />
            <span> Archive </span>
          </Menu.Item>
        }
        {
          !archivedClosed && <Menu.Item
            onClick={() => handleConfirmStatus({statusTo: "CLOSED", statusFrom: actualStatus, text: "closed", highlight: false})}
            key="changeToClosed"
          >
            <SolutionOutlined
              style={{
                fontSize: 16,
                color: "#2885DC"
              }}
            />
            <span> Mark Closed </span>
          </Menu.Item>
        }
        {
          !archivedClosed && ( item?.isOffer ) && (item?.status === "DRAFT" || item?.status === "SUBMITTED" || item?.status === "INPROGRESS") && <Menu.Item
          onClick={() => handelStatusChange({statusTo: "ACCEPTED", statusFrom: item?.actualOfferStatus, highlight: false, text: "Please upload the completely signed offer document", note: "Are you sure you want to change the status to pending? Usually, the seller agent handles status change to pending."})}
            key="changeToPending"
          >
            <SolutionOutlined
              style={{
                fontSize: 16,
                color: "#2885DC"
              }}
            />
            <span> Mark Pending </span>
          </Menu.Item>
        }
        {
          (item?.actualOfferStatus === "TERMINATED") && <Menu.Item
            onClick={() => handleCustomStatus({statusTo: "TERMINATED_ACCEPTED", statusFrom: item?.actualOfferStatus, title: "Are you sure you want to change the status back to pending? Usually, the seller agent handles status change to pending.", highlight: false})}
            key="changeToPending"
          >
            <SolutionOutlined
              style={{
                fontSize: 16,
                color: "#2885DC"
              }}
            />
            <span> Change back to pending </span>
          </Menu.Item>
        }
          {
          pending && <Menu.Item
            onClick={() => handleCustomStatus({statusTo: "TERMINATED", statusFrom: (item?.isProperty) ? currentStatus : actualStatus, title: item?.isProperty 
              ? "Are you sure you want to terminate the transaction for this offer and revert the listing status to Active?" 
              : "Are you sure you want to terminate or discontinue working on this offer?", content: "Note: Please ensure that either the 'Notice to Terminate' or the 'Extension or Termination of Contract' document has been submitted.", highlight: false})}
            key="changeToTerminate"
          >
            <SolutionOutlined
              style={{
                fontSize: 16,
                color: "#2885DC"
              }}
            />
            <span> {item?.isProperty ? "Terminate Transaction" : "Terminate Offer"} </span>
          </Menu.Item>
        }
        {
          archivedClosed && (item?.isOffer || item?.previousStatus) && <Menu.Item
            onClick={() => handleConfirmStatus({statusTo: "PREVIOUS_STATUS", statusFrom: actualStatus, text: previousCommonStatus?.value, highlight: false})}
            key="changeToPrevious"
          >
            <SolutionOutlined
              style={{
                fontSize: 16,
                color: "#2885DC"
              }}
            />
            <span> Change back to {previousCommonStatus?.value} </span>
          </Menu.Item>
        }
      </Menu>
    </>
  );
};

export default PanelMenuItem;
