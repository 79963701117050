import React, { useState } from "react";
import { Layout, Col, Row, Tabs, Space, Collapse, Spin } from "antd";
import { PlusOutlined, FileTextOutlined, HomeOutlined, UserOutlined } from "@ant-design/icons";
import { Reminders } from "./components/Reminders";
import Schedule from "./components/Schedule";
import CurrenClient from "./components/CurrentClient";
// import Notes from "./components/Notes";
import NewsLetterComponent from "./components/NewsLetterComponent";
import ResureMenu from "./components/ResureMenu";
// import { getKeyThenIncreaseKey } from "antd/lib/message";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import styles from "./style/landingPage.module.css";
import styles1 from "./style/CustomFileIcon.module.css"
import "./style/Landing.css";
import { SupportPage } from "./components/SupportPage";
import { Tutorilas } from "./components/Tutorilas";
import { useEffect } from "react";
import { landingPageAction } from "./state/actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import FacebookTimeline from './components/FacebookTimeline';
import TutorialsVideos from "./components/TutorialsVideos";
import { useMediaQuery } from "react-responsive";
import HomeOptions from "./components/HomeOptions";
import { documentAction } from "../DocumentSection/state/actions";
// import {GiHamburgerMenu} from "react-icons/gi"
const { Content, Sider } = Layout;
const { TabPane } = Tabs;
// const { Title } = Typography;

const Landing = () => {

  return (
    <>
      <Layout style={{ padding: "0px", marginTop: "-10px",   width: "100%",background:"red"}}>
        <Content
          style={{
            margin: 0,
            padding: 0,
            width: "100%",
          }}
        >
          <Row
            gutter={[4, 8]}
            style={{
              margin: 0,
              padding: 0,
              width: "100%",
            }}
          >
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div className={styles.landingPage}>
                <Switch>
                  {/* <-----testing-------> */}

                  <Route
                    exact
                    path="/support"
                    component={TabCommon}
                  />
                  <Route
                    exact
                    path="/metrics"
                    component={TabCommon}
                  />
                  <Route
                    exact
                    path="/"
                    component={TabCommon}
                  />
                  <Route path="*">
                    <Redirect to="/error" />
                  </Route>
                </Switch>
              </div>
            </Col>
          </Row>
          {/* <Button onClick={handleTest}>Test</Button> */}
        </Content>
      </Layout>
    </>
  );
};

function TabCommon() {
  const [currentDate, setCurrentDate] = useState(moment());
  const {renderComp} = useSelector(state=>state.landingPageReducer.supportReducer);
  const [toggle, settoggle] = useState(false);
  const { pathname } = useLocation();
  let pathnme = pathname?.split("/");
  let idx = (pathnme?.[1] === "support") ? "3" : (pathnme?.[1] === "metrics") ? "2" : "1";
  const [tabKey, settabKey] = useState(idx);
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({maxWidth:480})

  let dayIndex = moment(currentDate).weekday();

  const weekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  let dayOfWeek = weekDays[dayIndex];

  const handleChange = (key) => {
    if (key === "1") {
      history.push("/");
    } else if (key === "2") {
      history.push("/metrics");
    } else if (key === "3") {
      history.push("/support");
    }
  };
  const onChange = (key) => {
    if (key === "5") {
      window.open("/bugsReport");
    }
  };
  const { Panel } = Collapse;

  useEffect(()=>{
    settabKey(idx);
  }, [pathname]);

  useEffect(() => {
    if(tabKey === "3") dispatch(landingPageAction.renderSupportComponant("CONTACT"));
    if(tabKey === "1") dispatch(landingPageAction.renderSupportComponant('TUTORIALS'));
    // if(tabKey === "3") dispatch(landingPageAction.renderSupportComponant('CONTACT'));
    if(tabKey === "5") dispatch(landingPageAction.renderSupportComponant('TutorialsVideos'));
    if(renderComp === "CONTACT" && !toggle) settabKey("3");
}, [tabKey,dispatch,toggle,renderComp]);

useEffect(() => {
  return () => {
    dispatch(landingPageAction.renderSupportComponant("CONTACT"));
    settabKey("3");
  }
}, [])
const { getUserDetailsData, getLicenseData } = useSelector((state) => state.account);
const filteredDelegate = !!getUserDetailsData?.delegateId?._id ? getUserDetailsData?.delegatesAssigned?.filter((item) => String(item?.personId) === String(getUserDetailsData?.delegateId?._id)) : [];
const isDelegateHasLimitedAccess = filteredDelegate?.[0]?.specificTransaction || false;
const CustomFileAddIcon = ({origin}) => {
  return (
      <div style={{background:"#19376D", padding:"12px", borderRadius:"10px"}}>
          <div className={styles1.file_icon_wrapper}>
              {origin === "offer" ? <FileTextOutlined className={styles1.file_icon} /> : origin === "listing" ? <HomeOutlined className={styles1.file_icon} /> : <UserOutlined className={styles1.file_icon} />}
              <PlusOutlined className={styles1.plus_icon} />
          </div>
    </div>
  );
};
const handleCreateContact = () => {
  dispatch(documentAction.setCorpState({ drawerVisible: true }));
}

const handleCreateListing = () => {
  history.push({
      pathname:"/transactions",
      state:{modalState: {isListingModal:true}}
  });
}

const handleCreateOffer = () => {
  history.push({
      pathname:"/transactions",
      state:{modalState: {isOfferModal:true}}
  });
}
  return [
    <div className="landing_page_date" style={{ width: "50%" }}>
      {` ${dayOfWeek}, ${moment(currentDate).format("L").replace("/", "-").replace("/", "-")}`}
    </div>,
    <Tabs activeKey={idx} onChange={handleChange} className={styles.tabContainer}>
      <TabPane tab={<span className={styles.tabPaneTitleMyDash}>my dashboard</span>} key="1" className={styles.tabPaneContainer}>
        {idx === "1" && (
          <div className="landing_page" style={{ width: "100%", height: "100%" }}>
            <Col xs={20} sm={22} md={14} lg={12} xl={8}>
              <div
                style={{
                  margin: "0 -2px 0 -5px",
                  overflow: "hidden",

                  display: "flex",
                  flexDirection: "column",
                }}
                className="reminderContainer"
              >
                {/* Top Section */}
                <div
                  style={{
                    flex: 1, // Equal height for both sections
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                  }}
                >
                  {isDelegateHasLimitedAccess ? null : (
                    <>
                      <Row
                        align="middle"
                        style={{
                          cursor: "pointer",
                          color: "#19376D",
                          width: "100%",
                        }}
                        onClick={handleCreateOffer}
                      >
                        <div style={{ display: "flex", gap: "30px", width: "100%" }}>
                          <CustomFileAddIcon origin="offer" />
                          <span style={{ fontSize: "21px", fontWeight: "bold" }}>Create a new offer</span>
                        </div>
                      </Row>

                      <Row
                        align="middle"
                        style={{
                          cursor: "pointer",
                          color: "#19376D",
                          width: "100%",
                        }}
                        onClick={handleCreateListing}
                      >
                        <div style={{ display: "flex", gap: "30px", width: "100%" }}>
                          <CustomFileAddIcon origin="listing" />
                          <span style={{ fontSize: "21px", fontWeight: "bold" }}>Create a new listing</span>
                        </div>
                      </Row>
                    </>
                  )}

                  <Row
                    align="middle"
                    style={{
                      cursor: "pointer",
                      color: "#19376D",
                      width: "100%",
                    }}
                    onClick={handleCreateContact}
                  >
                    <div style={{ display: "flex", gap: "30px", width: "100%" }}>
                      <CustomFileAddIcon origin="contact" />
                      <span style={{ fontSize: "21px", fontWeight: "bold" }}>Create a new contact</span>
                    </div>
                  </Row>
                </div>

                {/* Bottom Section */}
                <div
                  style={{
                    flex: 1, // Equal height for both sections
                    overflowY: "auto", // Enable scrolling for content overflow
                  }}
                >
                  <div className={styles.remainderSection}>
                    <Reminders newDate={moment(new Date().setHours(9))} section="HOME" />
                  </div>

                  <div className={styles.scheduleSection}>
                    <Schedule newDate={moment(new Date().setHours(9))} section="HOME" />
                  </div>
                </div>
              </div>
            </Col>

            <Col xs={24} sm={16} md={14} lg={12} xl={8}>
              <div className="reminderContainer" style={{ marginRight: "-2px", overflow: "hidden" }}>
                <Space
                  direction="vertical"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  <HomeOptions />
                </Space>
              </div>
            </Col>

            <Col xs={16} sm={14} md={12} lg={12} xl={8}>
              <div className="reminderContainer" style={{ width: "100%", padding: "0", overflow: "hidden" }}>
                <FacebookTimeline />
              </div>
            </Col>
          </div>
        )}
      </TabPane>

      {/* <TabPane
        tab={<span className={styles.tabPaneTitleMetric}>metrics</span>}
        key="2"
        disabled
      >
        <div className="landing_page">
          <Col span={6} xs={24} sm={24} md={24} lg={6} xl={24}>
            <Sider
              className="site-layout-background landing_screenForm mozScrollbar reminderContainer"
              theme={"light"}
              width={"100%"}
            >
              <CurrenClient />
            </Sider>
          </Col>
        </div>
      </TabPane> */}

      <TabPane tab={<span className={styles.tabPaneTitleSupport}>support</span>} key="3" className={styles.tabSupport}>
        {/* aCCORDIAN for Small Screen */}
        {idx === "3" && (
          <div className={styles.accordianSubtitle}>
            <Collapse size="small" accordion defaultActiveKey={["1"]} onChange={onChange} style={{ width: "100%" }}>
              {/* <Panel header="NEWS / RE-Sure EVENTS" key="1">
                <Col xs={24} sm={24} md={24} lg={14} xl={16}>
                  <div
                    className="mozScrollbar reminderContainer"
                    style={{
                      border: "1px solid  rgba(0,0,0,0.2)",
                      borderRadius: "8px",
                      backgroundColor: "#fff",
                      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                      transition: "0.3s",
                      height: "calc(100vh - 250px)",
                      overflow: "auto",
                      width:'100%',
                      // minHeight: "73vh",
                      padding: "0rem 1rem",
                    }}
                  >
                    <Space
                      direction="vertical"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <NewsLetterComponent />
                    </Space>
                  </div>
                </Col>
              </Panel> */}
              {/* <Panel header="TUTORIALS" key="2">
              <Col xs={24} sm={24} md={24} lg={14} xl={16}>
                  <div
                    className={`${styles.tutorials.someClass} mozScrollbar reminderContainer`}
                    style={{
                      border: "1px solid  rgba(0,0,0,0.2)",
                      borderRadius: "8px",
                      backgroundColor: "#fff",
                      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                      transition: "0.3s",
                      overflow: "auto",
                      width:'100%',
                      // minHeight: "73vh",
                      padding: "0rem 1rem",
                    }}
                  >
                    <Space
                      direction="vertical"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
              <Tutorilas/>
              </Space>
                  </div>
                </Col>
              </Panel> */}
              {/* <Panel header="BLOG" key="3">
                <p>Resure Blog</p>
              </Panel> */}
              <Panel header="CONTACT" key="4">
                <Col xs={24} sm={24} md={24} lg={14} xl={16}>
                  <div
                    className={`${styles.contactSupport} mozScrollbar reminderContainer`}
                    style={{
                      border: "1px solid  rgba(0,0,0,0.2)",
                      borderRadius: "8px",
                      backgroundColor: "#fff",
                      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                      transition: "0.3s",

                      overflow: "auto",
                      // height:'100px',
                      width: "100%",
                      // minHeight: "73vh",
                      padding: "0rem 1rem",
                    }}
                  >
                    <Space
                      direction="vertical"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <SupportPage />
                    </Space>
                  </div>
                </Col>
              </Panel>
              <Panel header="Customer Support" key="5">
                <p>Customer Support</p>
              </Panel>

              <Panel header="Tutorial Videos and Q&A" key="6">
                <Col xs={24} sm={24} md={24} lg={14} xl={16}>
                  <div
                    className={`${styles.contactSupport} mozScrollbar reminderContainer`}
                    style={{
                      border: "1px solid  rgba(0,0,0,0.2)",
                      borderRadius: "8px",
                      backgroundColor: "#fff",
                      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                      transition: "0.3s",
                      overflow: "auto",
                      width: "100%",
                      padding: "0rem 1rem",
                    }}
                  >
                    <Space
                      direction="vertical"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <TutorialsVideos />
                    </Space>
                  </div>
                </Col>
              </Panel>
            </Collapse>
          </div>
        )}

        {idx === "3" && !isMobile && (
          <Row style={{ width: "100%" }} gutter={[2, 0]}>
            <Col xs={9} sm={9} md={8} lg={7} xl={6}>
              <div className="mozScrollbar reminderContainer">
                <Space
                  direction="vertical"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <ResureMenu settabKey={settabKey} settoggle={settoggle} />
                </Space>
              </div>
            </Col>
            <Col xs={15} sm={15} md={16} lg={17} xl={18}>
              <div className="mozScrollbar reminderContainer">
                <Space
                  direction="vertical"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "auto",
                  }}
                >
                  {/* {renderComp === "NEWS" && <NewsLetterComponent />} */}
                  {renderComp === "TUTORIALS" && <Tutorilas />}
                  {renderComp === "CONTACT" && <SupportPage />}
                  {renderComp === "TutorialsVideos" && <TutorialsVideos />}
                </Space>
              </div>
            </Col>
          </Row>
        )}
      </TabPane>
    </Tabs>,
  ];
}

export default Landing;
