import React, { useState } from "react";
import { Button, Row, Col } from "antd";
import { shallowEqual, useDispatch, useSelector, } from "react-redux";
import { clientAction } from "../state/actions";
import { TransactionLane } from "../../Rtd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import TransactionDetailsComponent from "./TransactionDetailComponent";
import { Loading } from "../../Common";

function TransactionDetails({
  id,
  setDocumentType,
  documentType,
  getClientDetail,
  newRedirectClientToOffer,
  setSelectedClientData,
  selectedClintData,
  tags,
}) {
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);
  const [keys, setKeys] = useState("");
  const [addressData, setAddressData] = useState();

  const { personDetailsLoading } = useSelector((state) => state.client.personDetailReducer, shallowEqual);

  let handleMenuChange = (event, Item) => {
    switch (event?.key) {
      case "0":
        break;

      case "1":
        break;

      case "2": {
        dispatch(clientAction.getDocumentryDetails("BUYER", Item._id));
        setDocumentType(true);
        setKeys(event.key);
        setAddressData(Item);
        break;
      }

      case "3": {
        setDocumentType(true);
        setKeys(event.key);
        break;
      }
      default:
        break;
    }
  };

  let showModal = () => {
    setVisible(true);
  };

  const unVisible = () => {
    setVisible(false);
  };

  return (
    <>
      {/* {personDetailsLoading  ? <div style={{marginTop:"30%"}}><Loading/></div>: */}
      {personDetailsLoading ? (
        <div style={{ marginTop:"25%" }}>
          <Loading />
        </div>
      ) : (
      <div>
        {documentType ? (
          <Row>
            <Col span={24}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <ArrowLeftOutlined onClick={() => setDocumentType(false)} />
                </div>
                {keys === "2" ? (
                  <>
                    <div>
                      <Button
                        type="primary"
                        style={{
                          width: "180px",
                          fontSize: "14px",
                          height: "33px",
                          paddingLeft: "20px",
                          borderRadius: "10px",
                          fontWeight: "700",
                          letterSpacing: "5px",
                          background: "#085191",
                          color: "white",
                          border: "none",
                        }}
                        onClick={showModal}
                      >
                        RTD
                      </Button>
                    </div>
                    <TransactionLane
                      visible={visible}
                      unVisible={unVisible}
                      selectedData={selectedClintData}
                      tags={tags}
                    />
                  </>
                ) : (
                  ""
                )}
              </div>
            </Col>
          </Row>
        ) : (
          <>
            <Row>
              <TransactionDetailsComponent
                setSelectedClientData={setSelectedClientData}
                id={id}
                handleMenuChange={handleMenuChange}
                newRedirectClientToOffer={newRedirectClientToOffer}
              />
            </Row>
          </>
        )}
      </div>
      )}
      {/* } */}
    </>
  );
}
export { TransactionDetails };
